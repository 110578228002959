import {Context, createContext, ReactNode, useState} from "react";
import {useSelector} from "react-redux";

import TopBar from "./TopBar";
import SideMenu from "./SideMenu";
import Footer from "./Footer";
import InnerLoader from "../loaders/InnerLoader";
import CookieHelper from "../../utils/helpers/cookieHelper";
import {AppKeys} from "../../utils/helpers/constants";

interface Props {
  active: string
  subMenu?: string
  title: string
  loading?: boolean
  backEnabled?: boolean
  searchType?: 'DOCUMENT' | 'VOUCHER' | 'ANY'
  children?: ReactNode
  navSearch?: ((params: string) => void) | undefined
}

export const ThemeContext: Context<string> = createContext('light');

const PageLayout = ({active, subMenu, title, backEnabled, searchType, navSearch, loading, children}: Props) => {

  const initTheme: string = CookieHelper.get(AppKeys.THEME) ?? 'light';
  const userProfile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile)
  const [theme, setTheme] = useState<string>(initTheme);
  const setThemeContext = (theme: string) => setTheme(theme);

  return (
    <ThemeContext.Provider value={theme}>
      <div id="layout-wrapper">
        <TopBar page={active} title={title} profile={userProfile} backEnabled={backEnabled} searchType={searchType}
                navSearch={navSearch} setThemeContext={setThemeContext}/>
        <SideMenu active={active} subMenu={subMenu} profile={userProfile}/>
        <div className="main-content">
          <div className="page-content">
            {loading ? <InnerLoader/> : children}
          </div>
          <Footer/>
        </div>
      </div>
    </ThemeContext.Provider>
  )
}

export default PageLayout;
