import {Fragment, useMemo, useState} from "react";
import toast from "react-hot-toast";
import {toTitleCase} from "../../../../utils/helpers/logicHelper";
import DataTable from "../../../../components/dataTable/DataTable";
import DeleteEntityModal from "../../../../components/modals/DeleteEntityModal";
import LoanConfigurationModal from "../../../../components/modals/LoanConfigurationModal";
import {CellContext, ColumnDef, ColumnHelper, createColumnHelper} from "@tanstack/react-table";
import {useDeleteLoanConfigurationMutation} from "../../../../store/slices/backoffice.slice";
import {LoanConfigurationColumn} from "../../../../components/dataTable/LoanConfigurationColumn";

type Props = {
  configs: ILoanConfig[]
  count: number
  loading: boolean
  permissions?: IProfilePermissions
}

const LoanConfigurationList = ({configs, count, loading, permissions}: Props) => {
  const initDeleteProps: IDeleteProps = {title: '', errorMsg: '', confirmMsg: '', confirmation: ''};

  const [size] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [config, setConfig] = useState<ILoanConfig | undefined>(undefined);
  const [deleteProps, setDeleteProps] = useState<IDeleteProps>(initDeleteProps);
  const [updateConfigurationRender, setUpdateConfigurationRender] = useState(false);
  const [deleteConfigurationRender, setDeleteConfigurationRender] = useState(false);
  const [deleteLoanConfiguration, {isLoading: isLoadingDelete}] = useDeleteLoanConfigurationMutation();

  const column: ColumnHelper<ILoanConfig> = createColumnHelper<ILoanConfig>();
  const actionColumn: ColumnDef<ILoanConfig, string> = (column.accessor('id', {
    header: () => <span>Action</span>,
    enableSorting: false,
    cell: (info: CellContext<ILoanConfig, string>) => {
      let config: ILoanConfig = info.row.original;
      return (
        <span className="d-flex gap-2 pointer">
          {/*{permissions?.["can-update-quick-pay-config"] &&*/}
          <button className="btn btn-sm btn-primary" onClick={() => openLoanConfigRender(config, "UPDATE")}>
							Update Config
						</button>
          {/*}*/}
          {/*{permissions?.["can-delete-quick-pay-config"] &&*/}
          <button className="btn btn-sm btn-danger" disabled={isLoadingDelete} onClick={() => deleteService(config, "DELETE")}>
							Delete Config {isLoadingDelete && <i className="bx bx-loader bx-spin"/>}
						</button>
          {/*}*/}
        </span>
      )
    }
  }))
  const columns: ColumnDef<ILoanConfig, string>[] = useMemo(() => [...LoanConfigurationColumn, actionColumn], []);

  const deleteService = (data: ILoanConfig, type: string): void => {
    let deleteProps: IDeleteProps = {
      title: `Remove ${toTitleCase(data?.loan_type)} Top-up`,
      errorMsg: `You are about to remove the top-up configuration for ${toTitleCase(data?.loan_type)}`,
      confirmMsg: 'Type in the top-up type (bold text) to delete',
      confirmation: (data && data.loan_type) ? data.loan_type : "DELETE_CONFIG",
      property: data
    }
    openLoanConfigRender(data, type);
    setDeleteProps(deleteProps);
  }

  const openLoanConfigRender = (data: ILoanConfig, type: string) => {
    setConfig(data);
    if(type === "UPDATE") setUpdateConfigurationRender(true);
    else if(type === "DELETE") setDeleteConfigurationRender(true);
  }

  const closeLoanConfigRender = (type: string) => {
    if(type === "UPDATE") setUpdateConfigurationRender(false);
    else if(type === "DELETE") setDeleteConfigurationRender(false);
    setTimeout(() => setConfig(undefined), 5000);
  }

  const deletePrompt = async () => {
    try {
      await deleteLoanConfiguration(config?.id).unwrap();
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const updateLoanConfigurationModal = updateConfigurationRender &&
		<LoanConfigurationModal initialData={config} onchange={() => closeLoanConfigRender("UPDATE")}/>

  const deleteLoanConfigurationModal = deleteConfigurationRender &&
		<DeleteEntityModal deleteProps={deleteProps} onchange={() => closeLoanConfigRender("DELETE")} actionPrompt={deletePrompt}/>

  return (
    <Fragment>
      {updateLoanConfigurationModal}
      {deleteLoanConfigurationModal}
      <div className="row">
        <div className="col-md-12">
          <div className="card card-body">
            <h5 className="card-title">Configuration</h5>
            <DataTable columns={columns} data={configs} pageIndex={pageIndex} size={size}
                       count={count} loadingState={loading} setPageIndex={(index: number) => setPageIndex(index)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default LoanConfigurationList;
