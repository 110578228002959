import {useState} from "react";
import {useSelector} from "react-redux";
import {Container} from "react-bootstrap";
import PageLayout from "../../../components/layouts/PageLayout";
import LoanConfigurationList from "./misc/LoanConfigurationList";
import {MenuType, SubMenuType} from "../../../utils/helpers/constants";
import {useGetLoanConfigurationQuery} from "../../../store/slices/backoffice.slice";
import LoanConfigurationModal from "../../../components/modals/LoanConfigurationModal";

const LoanConfiguration = () => {
  const [addConfigurationRender, setAddConfigurationRender] = useState(false);
  const profile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile);
  const {data: configs, isLoading} = useGetLoanConfigurationQuery('');

  const addLoanConfigurationModal = addConfigurationRender &&
    <LoanConfigurationModal onchange={() => setAddConfigurationRender(false)}/>

  return (
    <PageLayout active={MenuType.SUPPLY} subMenu={SubMenuType.LOAN} title={`Top-Up Configuration`} loading={isLoading}>
      {addLoanConfigurationModal}
      <Container fluid>
        {/*{profile.permissions?.["can-setup-quick-pay-config"] &&*/}
					<div className="d-flex justify-content-end gap-2 mb-3">
						<button className="btn btn-primary pointer-event" onClick={() => setAddConfigurationRender(true)}>
							<i className="bx bx-plus me-1"></i> Add Top-Up Configuration
						</button>
					</div>
        {/*}*/}

        <LoanConfigurationList configs={configs?.data ?? []} count={configs?.count ?? 0} loading={isLoading}
        permissions={profile?.permissions}/>
      </Container>
    </PageLayout>
  )
}

export default LoanConfiguration;
