import {Fragment, useState} from "react";
import {useSelector} from "react-redux";
import toast from "react-hot-toast";
import {Container, Row} from "react-bootstrap";
import {useLocation, useNavigate} from "react-router-dom";

import LoanRequestCard from "./misc/LoanRequestCard";
import SupportingDocument from "./misc/SupportingDocument";
import {ActionPromptEnum} from "../../../interfaces/enums";
import {toTitleCase} from "../../../utils/helpers/logicHelper";
import LoanRequestBreakdown from "./misc/LoanRequestBreakdown";
import PageLayout from "../../../components/layouts/PageLayout";
import {MenuType, SubMenuType} from "../../../utils/helpers/constants";
import {DropDown, DropDownItem} from "../../../components/modules/DropDown";
import DeleteEntityModal from "../../../components/modals/DeleteEntityModal";
import ActionPromptModal from "../../../components/modals/ActionPromptModal";
import LoanStatusCanvas from "../../../components/offcanvas/LoanStatusCanvas";
import {
  useDeleteLoanRequestMutation, useGetLoanRequestQuery, useGetOrganisationDriverProfileQuery
} from "../../../store/slices/backoffice.slice";


const LoanRequestInfo = () => {
  const navigate = useNavigate(), location = useLocation();
  const initDeleteProps: IDeleteProps = {title: '', errorMsg: '', confirmMsg: '', confirmation: ''};
  const profile: IProfile = useSelector((state: IStoreState) => state.auth.userProfile);

  const [loanCanvasRender, setLoanCanvasRender] = useState(false);
  const [repayRequestRender, setRepayRequestRender] = useState(false);
  const [rejectRequestRender, setRejectRequestRender] = useState(false);
  const [deleteRequestRender, setDeleteRequestRender] = useState(false);
  const [approveRequestRender, setApproveRequestRender] = useState(false);
  const [deleteProps, setDeleteProps] = useState<IDeleteProps>(initDeleteProps);

  const {data: request, isLoading} = useGetLoanRequestQuery(location.state?.id);
  const {data: organisationDriverConfig, isLoading: isLoadingProfile} = useGetOrganisationDriverProfileQuery("");
  const [deleteLoanRequest, {isLoading: isLoadingDelete}] = useDeleteLoanRequestMutation();

  const goBack = () => navigate(-1);

  if (!location.state || !location.state.id) goBack();

  const deleteService = (): void => {
    const loan = request?.data, partner = loan?.driver?.user;
    let deleteProps: IDeleteProps = {
      title: `Remove ${toTitleCase(loan.loan_config?.loan_type)} loan`,
      errorMsg: `You are about to remove the loan request for ${loan.loan_config?.loan_type}  for ${partner?.first_name} ${partner?.last_name} - ${partner?.email}`,
      confirmMsg: 'Type in the loan request reference to delete',
      confirmation: loan.reference,
      property: loan
    }
    setDeleteRequestRender(true);
    setDeleteProps(deleteProps);
  }

  const deletePrompt = async () => {
    try {
      await deleteLoanRequest(request?.data?.id).unwrap();
      goBack();
    } catch (err: any) {
      toast.error(err.data.message)
    }
  }

  const closeRepayRequestRender = () => setRepayRequestRender(false);
  const closeRejectRequestRender = () => setRejectRequestRender(false);
  const closeApproveRequestRender = () => setApproveRequestRender(false);

  const loanStatusCanvas = loanCanvasRender &&
		<LoanStatusCanvas updates={request?.data?.journey} onchange={() => setLoanCanvasRender(false)}/>

  const repayLoanRequestModal = repayRequestRender ?
    <ActionPromptModal onchange={closeRepayRequestRender} type={ActionPromptEnum.REPAY_TOP_UP_REQUEST}
                       props={request?.data?.id} confirmation={request?.data?.reference}/> : null;
  const rejectLoanRequestModal = rejectRequestRender ?
    <ActionPromptModal onchange={closeRejectRequestRender} type={ActionPromptEnum.REJECT_TOP_UP_REQUEST}
                       props={request?.data?.id} confirmation={request?.data?.reference}/> : null;
  const approveLoanRequestModal = approveRequestRender ?
    <ActionPromptModal onchange={closeApproveRequestRender} type={ActionPromptEnum.APPROVE_TOP_UP_REQUEST}
                       props={request?.data?.id} confirmation={request?.data?.reference}/> : null;
  const deleteLoanModal = deleteRequestRender &&
		<DeleteEntityModal deleteProps={deleteProps} onchange={() => setDeleteRequestRender(false)}
											 actionPrompt={deletePrompt}/>


  return (
    <PageLayout active={MenuType.SUPPLY} subMenu={SubMenuType.LOAN} backEnabled
                title={`Top-Up Request - ${request?.data?.reference ?? ""}`} loading={isLoading || isLoadingProfile}>
      {loanStatusCanvas}
      {deleteLoanModal}
      {repayLoanRequestModal}
      {rejectLoanRequestModal}
      {approveLoanRequestModal}
      <Container fluid>
        <div className="d-flex justify-content-end gap-2 mb-3">
          {(request && request?.data && request?.data.status === "PROCESSING") &&
						<Fragment>
              {/*{(*/}
              {/*    profile.permissions?.["can-approve-quick-pay-request"]*/}
              {/*    || profile.permissions?.["can-deny-quick-pay-request"]*/}
              {/*    || profile.permissions?.["can-delete-quick-pay-request"]*/}
              {/*  ) &&*/}
								<DropDown buttonText="Actions" variant="primary">
                  {/*{profile.permissions?.["can-approve-quick-pay-request"] &&*/}
										<DropDownItem text="Approve Top-Up Request" onclick={() => setApproveRequestRender(true)}/>
                  {/*}*/}
                  {/*{profile.permissions?.["can-deny-quick-pay-request"] &&*/}
										<DropDownItem text="Reject Top-Up Request" className="text-danger"
																	onclick={() => setRejectRequestRender(true)}/>
                  {/*}*/}
                  {/*{profile.permissions?.["can-delete-quick-pay-request"] &&*/}
										<DropDownItem text="Delete Top-Up Request" className="text-bg-danger" loading={isLoadingDelete}
																	onclick={() => deleteService()}/>
                  {/*}*/}
								</DropDown>
              {/*}*/}
						</Fragment>
          }
          {/*{(profile.permissions?.["can-repay-quick-pay-request"] && request && request.data*/}
          {/*    && (request.data.status === "DISBURSED" || request.data.status === "REPAYMENT")) &&*/}
					{/*	<button className="btn btn-primary pointer-event" onClick={() => setRepayRequestRender(true)}>*/}
					{/*		<i className="bx bxs-credit-card me-1"></i> Repay Top-Up*/}
					{/*	</button>*/}
          {/*}*/}
          {(request && request.data && (request.data.status === "DISBURSED" || request.data.status === "REPAYMENT")) &&
						<button className="btn btn-primary pointer-event" onClick={() => setRepayRequestRender(true)}>
							<i className="bx bxs-credit-card me-1"></i> Repay Top-Up
						</button>
          }

          <button className="btn btn-secondary pointer-event" onClick={() => setLoanCanvasRender(true)}>
            <i className="bx bx-map me-1"></i> Status History
          </button>
        </div>
        <Row className="h-100">
          <LoanRequestCard profile={request?.data?.driver ?? {}}/>
          <LoanRequestBreakdown request={request?.data ?? {}}
                                dynamicFields={organisationDriverConfig?.data[0]?.dynamic_fields ?? []}/>
          <SupportingDocument profile={request?.data?.driver ?? {}}
                              dynamicFields={organisationDriverConfig?.data[0]?.dynamic_fields ?? []}/>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default LoanRequestInfo;
